import { Skill } from "@/skills/models/skill.model";
import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";

export default class SkillService {
  private backend: BackendService;

  private skills: Skill[] = [];

  constructor(private authService: AuthService) {
    this.backend = new BackendService(
      process.env.VUE_APP_API_BASE_URL,
      authService
    );
  }

  async getById(id: string): Promise<Skill | null> {
    const data = await this.backend.request({
      method: "GET",
      path: `/skills/${id}`,
    });
    if (!data.result?.skillExpectations) {
      return {
        skillId: data.result.skillId,
        skillExpectations: [],
        name: data.result.name,
        description: data.result.description,
        hideOnProfile: data.result.hideOnProfile,
      };
    }
    return data.result;
  }

  async getAllNoCache(): Promise<Skill[]> {
    if (this.skills.length === 0) {
      const data = await this.backend.request({
        method: "GET",
        path: "/skills/nocache",
      });
      this.skills = data.result;
    }
    return this.skills;
  }

  async addSkill(skill: Skill): Promise<Skill> {
    const data = await this.backend.request({
      method: "POST",
      path: "/skills",
      body: skill,
    });
    this.skills = []; // clear cache

    return data.result;
  }

  async updateSkill(id: string, skill: Skill): Promise<Skill> {
    const data = await this.backend.request({
      method: "PUT",
      path: `/skills/${id}`,
      body: skill,
    });
    this.skills = []; // clear cache

    return data.result;
  }

  async deleteSkill(id: string): Promise<void> {
    await this.backend.request({
      method: "DELETE",
      path: `/skills/${id}`,
    });
    this.skills = []; // clear cache
  }
}
