import { Technology } from "@/technology/models/technology.model";
import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";

export default class TechnologyService {
  private backend: BackendService;

  private technologies: Technology[] = [];

  constructor(private authService: AuthService) {
    this.backend = new BackendService(
      process.env.VUE_APP_API_BASE_URL,
      authService
    );
  }

  async getById(id: string): Promise<Technology | null> {
    const data = await this.backend.request({
      method: "GET",
      path: `/technologies/${id}`,
    });
    return data.result;
  }

  async getAll(): Promise<Technology[]> {
    if (this.technologies.length === 0) {
      const data = await this.backend.request({
        method: "GET",
        path: "/technologies",
      });
      this.technologies = data.result;
    }
    return this.technologies;
  }

  async getAllNoCache(): Promise<Technology[]> {
    if (this.technologies.length === 0) {
      const data = await this.backend.request({
        method: "GET",
        path: "/technologies/nocache",
      });
      this.technologies = data.result;
    }
    return this.technologies;
  }

  async addTechnology(technology: Technology): Promise<Technology> {
    const data = await this.backend.request({
      method: "POST",
      path: "/technologies",
      body: technology,
    });
    this.technologies = []; // clear cache

    return data.result;
  }

  async updateTechnology(
    id: string,
    technology: Technology
  ): Promise<Technology> {
    const data = await this.backend.request({
      method: "PUT",
      path: `/technologies/${id}`,
      body: technology,
    });
    this.technologies = []; // clear cache

    return data.result;
  }

  async deleteTechnology(id: string): Promise<void> {
    await this.backend.request({
      method: "DELETE",
      path: `/technologies/${id}`,
    });
    this.technologies = []; // clear cache
  }
}
