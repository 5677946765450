import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";
import { SecurityRoleUser } from "@/security-roles/models/security-role.model";

export default class SecurityRoleService {
  private backend: BackendService;

  constructor(private authService: AuthService) {
    this.backend = new BackendService(
      process.env.VUE_APP_API_BASE_URL,
      authService
    );
  }

  async getAllSecurityUsers(): Promise<SecurityRoleUser[]> {
    const data = await this.backend.request({
      method: "GET",
      path: `/security-roles/`,
    });

    return data?.result;
  }

  async getSecurityRolesByEmployeeId(
    employeeID: string
  ): Promise<SecurityRoleUser[]> {
    try {
      const data = await this.backend.request({
        method: "GET",
        path: `/security-roles/${employeeID}`,
      });

      return data.result;
    } catch (error) {
      return [];
    }
  }

  async deleteSecurityRole(
    employeeID: string,
    roleName: string
  ): Promise<SecurityRoleUser[]> {
    const data = await this.backend.request({
      method: "DELETE",
      path: `/security-roles/${employeeID}/role/${roleName}`,
    });

    return data.result;
  }

  async setSecurityRole(
    name: string,
    employeeName: string,
    employeeId: string,
    context?: string[]
  ): Promise<SecurityRoleUser[]> {
    const body: any = {
      name,
      employeeName,
      employeeId,
    };

    if (context) {
      body["context"] = context;
    }

    const data = await this.backend.request({
      method: "PUT",
      path: `/security-roles/${employeeId}`,
      body: body,
    });

    return data.result;
  }
}
