import { SchemaField } from "../../models/SchemaField";

export interface JobProfileFE {
  jobProfileId: string;
  jobFamilyId: string;
  jobFamilyName: string;
  name: string;
  nameAbbrev: string;
  description: string;
  jobLevel: number;
}

export interface JobProfileBE {
  jobProfileId: string;
  jobFamilyId: string;
  name: string;
  nameAbbrev: string;
  description: string;
  jobLevel: number;
}

export function mapJobProfileToFrontEnd(
  jobProfile: JobProfileBE,
  jobFamilyName: string,
  jobLevel: string
): JobProfileFE {
  return {
    jobProfileId: jobProfile.jobProfileId,
    jobFamilyId: jobProfile.jobFamilyId,
    jobFamilyName: jobFamilyName,
    name: jobProfile.name,
    nameAbbrev: jobProfile.nameAbbrev,
    description: jobProfile.description,
    jobLevel: Number(jobLevel),
  };
}

export function getJobProfileSchema(): SchemaField[] {
  return [
    {
      name: "name",
      type: "text",
      label: "Job Profile Name",
      showInList: true,
      readOnly: false,
    },
    {
      name: "jobFamilyName",
      type: "text",
      label: "Job Family",
      showInList: true,
      readOnly: true,
    },
    {
      name: "description",
      type: "text",
      label: "Job Description",
      multiline: true,
      showInList: false,
    },
    {
      name: "jobLevel",
      type: "number",
      label: "Workday Job Level",
      showInList: false,
      integer: true,
      min: 5,
      max: 13,
    },
  ];
}
