import axios from "axios";
import { AxiosInstance } from "axios";
import { v4 as uuidv4 } from "uuid";
import { AuthService } from "./auth.service";

export type RequestOptions = {
  method: "GET" | "POST" | "PUT" | "DELETE";
  path: string;
  queryParams?: Record<string, string>;
  body?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export class BackendService {
  private ax: AxiosInstance;

  constructor(baseURL: string, private authService: AuthService) {
    this.ax = axios.create({
      baseURL,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async request(options: RequestOptions, retry = true): Promise<any> {
    await this.authService.init();
    const token = this.authService.getIdToken();

    return this.ax
      .request({
        method: options.method,
        url: options.path,
        params: options.queryParams,
        data: options.body,
        headers: {
          Authorization: `Bearer ${token}`,
          "Correlation-Object": JSON.stringify({ correlationId: uuidv4() }),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // if the request fails due to an expired or bad ID token, use the refresh
        // token to obtain a new ID token and then retry the request once
        if (
          retry &&
          error.response?.status === 401 &&
          (error.response?.data?.message === "The incoming token has expired" ||
            error.response?.data?.message === "Unauthorized")
        ) {
          this.authService.refreshAuth();
          return this.request(options, false);
        }
        console.error(error);
        throw this.formatError(error);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private formatError(error: any): string {
    let msg = error;
    if (error.response?.data) {
      msg = `Error: (${error.response.data.errorCode}) ${error.response.data.message}`;
    }
    return msg;
  }
}
