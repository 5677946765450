import { PrerequisiteRole } from "@/roles/models/role.model";
import { SchemaField } from "../../models/SchemaField";
import { Duration } from "../../models/duration.model";
import { MasteryLevel } from "./lr_role.model";

export type DeliveryMethod =
  | "Digital"
  | "Instructor-Led"
  | "On-Demand"
  | "In-Person"
  | "Hybrid"
  | "Self-Directed";

export type Partner = "AWS" | "Google Cloud" | "Microsoft Azure" | "Salesforce";

export type BE_LearningResource = {
  // Back-end model
  resourceId: string;
  name: string;
  description: string;
  link: string;
  deliveryMethod: DeliveryMethod[]; //!!! Rename to plural on back-end
  source: string;
  partners: Partner[];
  duration?: Duration;
  cost?: number;
  // tags
  organizations?: string[];
  coreCompetencies?: string[];
  technologies?: string[];
  roles: PrerequisiteRole[];
};

export type FE_LearningResource = {
  // Front-end model
  id: string;
  name: string;
  description: string;
  link: string;
  deliveryMethods: DeliveryMethod[];
  roleId?: string;
  roleName: string;
  masteryLevel: MasteryLevel;
  source: string;
  partners: Partner[];
  duration?: Duration;
  cost?: number;
  // tags
  organizations?: string[];
  coreCompetencies?: string[];
  technologies?: string[];
  roles: PrerequisiteRole[];
};

export function getLearningResourceSchema(): SchemaField[] {
  return [
    {
      name: "name",
      type: "text",
      label: "Name",
      required: true,
      showInList: true,
      readOnly: true,
    },
    {
      name: "description",
      type: "text",
      label: "Description",
      multiline: true,
      required: true,
      readOnly: true,
    },
    {
      name: "source",
      type: "text",
      label: "Source",
      showInList: true,
      readOnly: true,
    },
    {
      name: "deliveryMethods",
      type: "select",
      label: "Delivery methods",
      options: ["Digital", "Instructor-Led", "On-Demand", "In-Person"],
      multiple: true,
      readOnly: true,
    },
    {
      name: "duration",
      type: "duration",
      label: "Duration",
      readOnly: true,
    },

    {
      name: "partners",
      type: "select",
      label: "Partners",
      options: ["AWS", "Google Cloud", "Microsoft Azure", "Salesforce"],
      multiple: true,
      readOnly: true,
    },
    {
      name: "link",
      type: "text",
      label: "Link",
      required: true,
      showInList: true,
      readOnly: true,
    },
    {
      name: "roles",
      type: "array",
      label: "Role tags",
      elements: {
        type: "prerequisite-role",
      },
    },
    {
      name: "organizations",
      type: "select",
      label: "Organization tags",
      options: ["Consulting", "Global Services"],
      multiple: true,
    },
    {
      name: "coreCompetencies",
      type: "select",
      label: "Core competency tags",
      options: [
        "Deliver Exceptionally",
        "Grow Expertise",
        "Grow Slalom",
        "Lead",
      ],
      multiple: true,
    },
    {
      name: "technologies",
      type: "select",
      label: "Technology tags",
      options: [],
      multiple: true,
    },
  ];
}

export function getBlankLearningResource(): FE_LearningResource {
  return {
    id: "",
    name: "",
    description: "",
    link: "",
    deliveryMethods: [],
    roleName: "",
    masteryLevel: "General",
    source: "",
    partners: [],
    duration: {
      amount: 0,
      unit: "Hours",
    },
    cost: 0,
    roles: [],
    organizations: [],
    technologies: [],
    coreCompetencies: [],
  };
}

export function mapLearningResourceToFrontEnd(
  resource: BE_LearningResource
): FE_LearningResource {
  return {
    id: resource.resourceId,
    name: resource.name,
    description: resource.description,
    link: resource.link,
    deliveryMethods: resource.deliveryMethod,
    roleName: resource.roles?.length ? resource.roles[0].name : "",
    masteryLevel: resource.roles?.length
      ? resource.roles[0].masteryLevels[0]
      : "General",
    source: resource.source,
    partners: resource.partners,
    duration: resource.duration,
    cost: resource.cost,
    roles: resource.roles,
    organizations: resource.organizations,
    technologies: resource.technologies,
    coreCompetencies: resource.coreCompetencies,
  };
}

export function mapLearningResourceToBackEnd(
  resource: FE_LearningResource
): BE_LearningResource {
  return {
    resourceId: resource.id || "",
    name: resource.name,
    description: resource.description,
    link: resource.link,
    deliveryMethod: resource.deliveryMethods,
    roles: resource.roles,
    source: resource.source,
    partners: resource.partners,
    duration: resource.duration,
    cost: resource.cost,
  };
}
