import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";
import { Role } from "@/roles/models/role.model";

export default class RoleService {
  private backend: BackendService;

  private roles: Role[] = [];

  constructor(private authService: AuthService) {
    this.backend = new BackendService(
      process.env.VUE_APP_API_BASE_URL,
      authService
    );
  }

  async getById(id: string): Promise<Role | null> {
    const data = await this.backend.request({
      method: "GET",
      path: `/roles/${id}`,
    });
    return data.result;
  }

  async getAll(): Promise<Role[]> {
    if (this.roles.length === 0) {
      const data = await this.backend.request({
        method: "GET",
        path: "/roles/all",
      });
      this.roles = data.result;
    }
    return this.roles;
  }

  async getAllNoCache(): Promise<Role[]> {
    if (this.roles.length === 0) {
      const data = await this.backend.request({
        method: "GET",
        path: "/roles/nocache",
      });
      this.roles = data.result;
    }
    return this.roles;
  }

  async addRole(role: Role): Promise<Role> {
    const data = await this.backend.request({
      method: "POST",
      path: "/roles",
      body: role,
    });
    this.roles = []; // clear cache

    return data.result;
  }

  async updateRole(id: string, role: Role): Promise<Role> {
    const data = await this.backend.request({
      method: "PUT",
      path: `/roles/${id}`,
      body: role,
    });
    this.roles = []; // clear cache

    return data.result;
  }

  async deleteRole(id: string): Promise<void> {
    await this.backend.request({
      method: "DELETE",
      path: `/roles/${id}`,
    });
    this.roles = []; // clear cache
  }

  async getRoleIdByName(roleName: string): Promise<string> {
    const roles = await this.getAll();
    for (const role of roles) {
      if (role.name === roleName) {
        return role.roleId;
      }
    }
    return "";
  }
}
