import { FE_LearningResource } from "./learningResource.model";
import { MasteryLevel } from "./lr_role.model";

export type LearningTag = {
  resourceId: string;
  roles?: RoleTag[];
  organizations?: string[];
  coreCompetencies?: string[];
  technologies?: string[];
};

export type RoleTag = {
  roleName: string;
  roleId: string;
  masteryLevels: MasteryLevel[];
};

export function mapLearningTagsToBackEnd(
  resource: FE_LearningResource
): LearningTag {
  const beRoles = [];
  for (const role of resource.roles) {
    beRoles.push({
      roleId: role.roleId,
      roleName: role.name,
      masteryLevels: role.masteryLevels,
    });
  }

  return {
    resourceId: resource.id,
    roles: beRoles,
    organizations: resource.organizations,
    coreCompetencies: resource.coreCompetencies,
    technologies: resource.technologies,
  };
}
