import { SchemaField } from "../../models/SchemaField";
import { JobExpectation } from "./jobExpectation.model";
import {
  JobSkill,
  mapJobSkillToFrontEnd,
  mapJobSkillToBackEnd,
} from "./jobSkill.model";

export interface JobOrganization {
  jobOrganizationId: string;
  name: string;
  description: string;
  skills: JobSkill[];
  expectations: JobExpectation[];
}

export function mapJobOrganizationToFrontEnd(
  jobOrganization: JobOrganization
): JobOrganization {
  return {
    jobOrganizationId: jobOrganization.jobOrganizationId,
    name: jobOrganization.name,
    description: jobOrganization.description,
    skills: mapJobSkillToFrontEnd(jobOrganization.skills),
    expectations: jobOrganization.expectations || [],
  };
}

export function mapJobOrganizationToBackEnd(
  jobOrganization: JobOrganization
): JobOrganization {
  return {
    jobOrganizationId: jobOrganization.jobOrganizationId,
    name: jobOrganization.name,
    description: jobOrganization.description,
    skills: mapJobSkillToBackEnd(jobOrganization.skills),
    expectations: jobOrganization.expectations || [],
  };
}

export function getJobOrganizationSchema(): SchemaField[] {
  return [
    {
      name: "name",
      type: "text",
      label: "Name",
      showInList: true,
      readOnly: true,
    },
    {
      name: "description",
      type: "text",
      label: "Description",
      multiline: true,
      showInList: false,
    },
    {
      name: "skills",
      type: "table",
      label: "Skills",
      elements: {
        type: "job-skill",
      },
    },
    {
      name: "expectations",
      type: "table",
      label: "Expectations",
      elements: {
        type: "job-expectation",
      },
    },
  ];
}
