import { SkillExpectation } from "@/skills/models/skill.model";
import { CoreCompetency } from "../../models/coreCompetency.model";
import { SkillProficiency } from "../../models/skillProficiency.model";

export interface JobSkill {
  skillId: string;
  skillName: string;
  coreCompetency: CoreCompetency;
  proficiencyLevels: SkillProficiency[];
  hasProficiencyLevels: boolean;
  skillExpectations: SkillExpectation[];
}

export function getBlankJobSkill(): Partial<JobSkill> {
  return {
    skillId: "",
    skillName: "",
    proficiencyLevels: [-1, -1, -1, -1, -1, -1, -1, -1, -1],
    hasProficiencyLevels: false,
    skillExpectations: [],
  };
}

export function mapJobSkillToFrontEnd(skills: JobSkill[]): JobSkill[] {
  const editedSkills: JobSkill[] = [];

  if (skills) {
    skills.forEach((skill) => {
      let s: JobSkill;
      if (skill.proficiencyLevels.length === 0) {
        s = {
          coreCompetency: skill.coreCompetency,
          skillId: skill.skillId,
          skillName: skill.skillName,
          proficiencyLevels: [-1, -1, -1, -1, -1, -1, -1, -1, -1],
          hasProficiencyLevels: false,
          skillExpectations: skill.skillExpectations || [],
        };
      } else {
        s = {
          coreCompetency: skill.coreCompetency,
          skillId: skill.skillId,
          skillName: skill.skillName,
          proficiencyLevels: decrementJobLevels(skill.proficiencyLevels),
          hasProficiencyLevels: true,
          skillExpectations: skill.skillExpectations || [],
        };
      }

      editedSkills.push(s);
    });
  }

  return editedSkills;
}

export function mapJobSkillToBackEnd(skills: JobSkill[]): JobSkill[] {
  const editedSkills: JobSkill[] = [];

  if (skills) {
    skills.forEach((skill) => {
      let s: JobSkill;
      if (
        skill.proficiencyLevels.length > 0 &&
        !hasProficiencyLevelsFilledOut(skill.proficiencyLevels)
      ) {
        s = {
          coreCompetency: skill.coreCompetency,
          skillId: skill.skillId,
          skillName: skill.skillName,
          proficiencyLevels: [],
          hasProficiencyLevels: false,
          skillExpectations: skill.skillExpectations || [],
        };
      } else {
        s = {
          coreCompetency: skill.coreCompetency,
          skillId: skill.skillId,
          skillName: skill.skillName,
          proficiencyLevels: incrementJobLevels(skill.proficiencyLevels),
          hasProficiencyLevels: true,
          skillExpectations: skill.skillExpectations || [],
        };
      }
      editedSkills.push(s);
    });
  }
  return editedSkills;
}

export function hasProficiencyLevelsFilledOut(
  proficiencyLevels: SkillProficiency[]
): boolean {
  let hasProficiencyLevel = false;
  proficiencyLevels.forEach((level) => {
    if (level.valueOf() !== -1) {
      hasProficiencyLevel = true;
    }
  });
  return hasProficiencyLevel;
}

function decrementJobLevels(
  proficiencyLevels: SkillProficiency[]
): SkillProficiency[] {
  const editedLevels: SkillProficiency[] = [];
  if (proficiencyLevels) {
    proficiencyLevels.forEach((level) => {
      editedLevels.push(level - 1);
    });
  }
  return editedLevels;
}

function incrementJobLevels(
  proficiencyLevels: SkillProficiency[]
): SkillProficiency[] {
  const editedLevels: SkillProficiency[] = [];
  if (proficiencyLevels) {
    proficiencyLevels.forEach((level) => {
      if (level.valueOf() !== -1) {
        editedLevels.push(level + 1);
      } else {
        editedLevels.push(level);
      }
    });
  }
  return editedLevels;
}
