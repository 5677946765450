import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";

export default class SearchService {
  private backend: BackendService;

  constructor(private authService: AuthService) {
    this.backend = new BackendService(
      process.env.VUE_APP_API_BASE_URL,
      authService
    );
  }

  async getSearchValue(query: { [param: string]: string }): Promise<any> {
    const data = await this.backend.request({
      method: "GET",
      path: "/advanced-search",
      queryParams: query,
    });
    return data?.result?.searchResults;
  }
}
