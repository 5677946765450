import {
  Certification,
  Partner,
} from "@/certification/models/certification.model";
import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";

export default class CertificationService {
  private backend: BackendService;

  private certifications: Certification[] = [];
  private partners: Partner[] = [];
  private partnersMap: Record<string, string> = {};

  constructor(private authService: AuthService) {
    this.backend = new BackendService(
      process.env.VUE_APP_API_BASE_URL,
      authService
    );
  }

  async getById(id: string): Promise<Certification | null> {
    const data = await this.backend.request({
      method: "GET",
      path: `/certifications/${id}`,
    });
    const cert = data.result;
    cert.partner = this.partnersMap[data.result.partnerId];
    return cert;
  }

  async getAll(): Promise<Certification[]> {
    if (this.certifications.length === 0) {
      const data = await this.backend.request({
        method: "GET",
        path: `/certifications`,
      });
      const certs = data.result;
      for (const cert of certs) {
        cert.partner = this.partnersMap[cert.partnerId];
      }
      this.certifications = certs.filter(
        (cert: Certification) => !cert.isDeleted
      );
    }
    return this.certifications;
  }

  async addCertification(
    certification: Partial<Certification>
  ): Promise<Certification> {
    for (const partner of this.partners) {
      if (partner.name === certification.partner) {
        certification.partnerId = partner.partnerId;
      }
    }

    const certBody = {
      name: certification.name,
      partnerId: certification.partnerId,
    };

    const data = await this.backend.request({
      method: "POST",
      path: "/certifications",
      body: certBody,
    });
    this.certifications = []; // clear cache
    return data.result;
  }

  async updateCertification(
    certification: Certification
  ): Promise<Certification> {
    let newPartnerId;
    for (const partner of this.partners) {
      if (partner.name === certification.partner) {
        newPartnerId = partner.partnerId;
      }
    }
    const updateBody = {
      name: certification.name,
      partnerId: newPartnerId,
    };

    const data = await this.backend.request({
      method: "PUT",
      path: `/certifications/${certification.certificationId}`,
      body: updateBody,
    });
    this.certifications = []; // clear cache
    return data.result;
  }

  async deleteCertification(id: string): Promise<void> {
    await this.backend.request({
      method: "DELETE",
      path: `/certifications/${id}`,
    });
    this.certifications = []; // clear cache
  }

  async getAllPartners(): Promise<Partner[]> {
    const data = await this.backend.request({
      method: "GET",
      path: "/certifications/partners/",
    });
    this.partners = data.result;
    for (const partner of this.partners) {
      this.partnersMap[partner.partnerId!] = partner.name;
    }
    return this.partners;
  }

  async getByIssuerId(issuerId: string): Promise<Partner> {
    const data = await this.backend.request({
      method: "GET",
      path: `/certifications/partners/${issuerId}`,
    });
    return data.result;
  }

  async addPartner(issuer: Partner): Promise<Partner> {
    const data = await this.backend.request({
      method: "POST",
      path: "/certifications/partners/",
      body: issuer,
    });
    return data.result;
  }

  async updatePartner(issuer: Partner): Promise<Partner> {
    const data = await this.backend.request({
      method: "PUT",
      path: `/certifications/partners/${issuer.partnerId}`,
      body: issuer,
    });
    return data.result;
  }

  async deletePartner(issuerId: string): Promise<Partner> {
    const data = await this.backend.request({
      method: "DELETE",
      path: `/certifications/partners/${issuerId}`,
    });
    return data.result;
  }
}
