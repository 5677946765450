import { Industry } from "@/industry/models/industry.model";
import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";

export default class IndustryService {
  private backend: BackendService;

  private industries: Industry[] = [];

  constructor(private authService: AuthService) {
    this.backend = new BackendService(
      process.env.VUE_APP_API_BASE_URL,
      authService
    );
  }

  async getById(id: string): Promise<Industry | null> {
    const data = await this.backend.request({
      method: "GET",
      path: `/industries/${id}`,
    });
    if (data.result) {
      if (!data.result.subIndustries) {
        data.result.subIndustries = [];
      }
      data.result.subIndustriesNames = data.result.subIndustries.map(
        (industry: any) => industry.name
      );
    }
    return data.result;
  }

  async getAll(): Promise<Industry[]> {
    if (this.industries.length === 0) {
      const data = await this.backend.request({
        method: "GET",
        path: "/industries",
      });
      this.industries = data.result;
    }
    return this.industries;
  }

  async getAllNoCache(): Promise<Industry[]> {
    if (this.industries.length === 0) {
      const data = await this.backend.request({
        method: "GET",
        path: "/industries/nocache",
      });
      this.industries = data.result;
      for (const industry of this.industries) {
        if (!industry.subIndustries) {
          industry.subIndustries = [];
        }
        industry.subIndustriesNames = industry.subIndustries.map(
          (industry: any) => industry.name
        );
      }
    }
    return this.industries;
  }

  async addIndustry(industry: Industry): Promise<Industry> {
    const data = await this.backend.request({
      method: "POST",
      path: "/industries",
      body: industry,
    });
    this.industries = []; // clear cache

    return data.result;
  }

  async updateIndustry(id: string, industry: Industry): Promise<Industry> {
    const data = await this.backend.request({
      method: "PUT",
      path: `/industries/${id}`,
      body: industry,
    });
    this.industries = []; // clear cache

    return data.result;
  }

  async deleteIndustry(id: string): Promise<void> {
    await this.backend.request({
      method: "DELETE",
      path: `/industries/${id}`,
    });
    this.industries = []; // clear cache
  }
}
