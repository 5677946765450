import {
  BE_LearningResource,
  FE_LearningResource,
  mapLearningResourceToFrontEnd,
} from "@/learning/models/learningResource.model";
import { mapLearningTagsToBackEnd } from "@/learning/models/learningTag.model";
import RoleService from "@/services/role.service";
import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";

export default class LearningResourceService {
  private backend: BackendService;

  private resources: FE_LearningResource[] = [];

  constructor(
    private authService: AuthService,
    private roleService: RoleService
  ) {
    this.backend = new BackendService(
      process.env.VUE_APP_API_BASE_URL,
      authService
    );
  }

  async getByResourceId(
    resourceId: string
  ): Promise<FE_LearningResource | null> {
    const data = await this.backend.request({
      method: "GET",
      path: `/learning/resources/${resourceId}`,
    });
    return mapLearningResourceToFrontEnd(data.result);
  }

  async getAll(): Promise<FE_LearningResource[]> {
    await this.getData();

    return this.resources;
  }

  async getData() {
    const data = await this.backend.request({
      method: "GET",
      path: "/advanced-search",
      queryParams: { type: "learning" },
    });

    this.resources = [
      ...data.result.searchResults.map((beRes: BE_LearningResource) =>
        mapLearningResourceToFrontEnd(beRes)
      ),
    ];
  }

  async getSortedFiltered(options: any): Promise<FE_LearningResource[]> {
    const { search, sortBy } = options;
    this.resources = [];
    if (!sortBy?.length && !search) {
      return await this.getAll();
    } else {
      const queryParams: Record<string, string> = { type: "learning" };
      if (sortBy.length) {
        queryParams.sort = `${sortBy[0].key} ${sortBy[0].order}`;
      }
      if (search) {
        queryParams.q = search;
      }

      const data = await this.backend.request({
        method: "GET",
        path: "/advanced-search",
        queryParams,
      });

      this.resources = [
        ...data.result.searchResults.map((beRes: BE_LearningResource) =>
          mapLearningResourceToFrontEnd(beRes)
        ),
      ];
      return this.resources;
    }
  }

  async editResource(
    resourceId: string,
    resource: FE_LearningResource
  ): Promise<void> {
    const tags = mapLearningTagsToBackEnd(resource);
    await this.backend.request({
      method: "PUT",
      path: `/learning/tags/${resourceId}`,
      body: tags,
    });
    this.resources = []; // clear cache
  }
}
