import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("../views/Home.vue"),
    },
    // {
    //   path: "/learningResources",
    //   name: "Browse learning resources",
    //   component: () => import("../learning/views/LearningResourcesPage.vue"),
    // },
    {
      path: "/technologies",
      name: "Technologies",
      component: () => import("../technology/views/TechnologiesPage.vue"),
    },
    {
      path: "/industries",
      name: "Industries",
      component: () => import("../industry/views/IndustriesPage.vue"),
    },
    {
      path: "/skills",
      name: "Skills",
      component: () => import("../skills/views/SkillsPage.vue"),
    },
    {
      path: "/roles",
      name: "Roles",
      component: () => import("../roles/views/RolesPage.vue"),
    },
    {
      path: "/security-roles/:employeeId?",
      name: "Security Roles",
      component: () => import("../security-roles/views/SecurityRolesPage.vue"),
    },
    {
      path: "/jobs/:orgId?/:groupId?/:familyId?",
      name: "Jobs",
      component: () => import("../jobs/views/JobsPage.vue"),
    },
    {
      path: "/certifications",
      name: "Certifications",
      component: () => import("../certification/views/CertificationsPage.vue"),
    },
    {
      path: "/certifications/issuers",
      name: "Issuers",
      component: () => import("../issuer/views/IssuersPage.vue"),
    },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: () => import("../views/404.vue"),
    },
  ],
});

export default router;
