import { IdToken, AccessToken } from "../models/token.model";
import { TokenHelper } from "./token.helper";
import axios from "axios";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export class AuthService {
  idToken = "";

  async init(): Promise<void> {
    const loggedIn = await this.isLoggedIn();
    if (!loggedIn) {
      this.login();
    }
  }

  // check if a user is logged in with valid tokens
  async isLoggedIn(): Promise<boolean> {
    const urlCode = this.getUrlCode();
    if (urlCode) {
      this.codeAuth(urlCode); // call function to check if code is valid and get user tokens
      return true;
    } else {
      const loggedIn = await this.checkTokens();
      return loggedIn;
    }
  }

  /**
   * Check if we have tokens - if they are expired we will refresh them in the http interceptor
   */
  async checkTokens(): Promise<boolean> {
    if (
      cookies.isKey("access_token") &&
      cookies.isKey("id_token") &&
      cookies.isKey("refresh_token")
    ) {
      // Check if access token exists and is parseable
      const accessToken = this.getAccessToken();
      const decodedAccessToken =
        TokenHelper.DecodeToken<AccessToken>(accessToken);
      if (!decodedAccessToken) {
        return false;
      }

      // Check if access token comes from this app
      if (decodedAccessToken.client_id !== process.env.VUE_APP_CLIENT_ID) {
        return false;
      }

      // Check if id and refresh tokens exist and have a length
      const idToken = this.getIdToken();
      const refreshToken = this.getRefreshToken();
      if (idToken.length <= 0 || refreshToken.length <= 0) {
        return false;
      }

      // JWTs are Base64 Encoded. In order to get the payload, we must decode it
      const decodedIdToken = TokenHelper.DecodeToken<IdToken>(idToken);
      if (!decodedIdToken) {
        return false;
      }
      return true;
    }
    return false;
  }

  // get the authorization code that is returned in the url bar
  getUrlCode(): string | null {
    const url = window.location.href;
    const codeStart = url.indexOf("code");
    if (codeStart !== -1) {
      return url.substring(codeStart + 5);
    }
    return null;
  }

  // redirect to the login page if user is not authorized, store the tokens otherwise
  setTokens(tokens?: Record<string, string>): void {
    if (!tokens) {
      this.loginRedirect();
    } else {
      const idToken = tokens["id_token"];
      cookies.set("access_token", tokens["access_token"], undefined, "/");
      cookies.set("refresh_token", tokens["refresh_token"], undefined, "/");
      cookies.set("id_token", idToken, undefined, "/");
      // const decodedIdToken = TokenHelper.DecodeToken<IdToken>(idToken);
    }
  }

  unsetTokens(): void {
    cookies.remove("access_token");
    cookies.remove("refresh_token");
    cookies.remove("id_token");
  }

  login(): void {
    // call cognito to validate non-sso user...
    this.loginRedirect();
  }

  // redirect the user to the login page
  loginRedirect(): void {
    this.setRouteRedirect(window.location.href);
    const login_url =
      process.env.VUE_APP_COGNITO_DOMAIN +
      "/authorize?&response_type=code&" +
      "client_id=" +
      process.env.VUE_APP_CLIENT_ID +
      "&redirect_uri=" +
      this.here();
    this.goToUrl(login_url);
  }

  /**
   * Calls cognito token endpoint to exchange authorization code
   * for cognito user tokens
   */
  codeAuth(code: string): void {
    this.unsetTokens();
    const header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const options = {
      headers: header,
    };
    const body = [
      "grant_type=authorization_code",
      `client_id=${process.env.VUE_APP_CLIENT_ID}`,
      `redirect_uri=${this.here()}`,
      `code=${code}`,
    ].join("&");
    axios.post(process.env.VUE_APP_TOKEN_AUTH_URL, body, options).then(
      async (tokens) => {
        this.setTokens(tokens.data);
        const route = await this.getRoute();
        this.goToUrl(route);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  refreshAuth(): void {
    const header = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const options = {
      headers: header,
    };
    const body = [
      "grant_type=refresh_token",
      `client_id=${process.env.VUE_APP_CLIENT_ID}`,
      `refresh_token=${this.getRefreshToken()}`,
    ].join("&");
    axios.post(process.env.VUE_APP_TOKEN_AUTH_URL, body, options).then(
      async (tokens) => {
        this.setTokens(tokens.data);
        const route = await this.getRoute();
        this.goToUrl(route);
      },
      (err) => {
        console.error(err);
        this.unsetTokens();
        this.loginRedirect();
      }
    );
  }

  // function that calls window.location.href but is testable
  goToUrl(url: string): void {
    window.location.href = url;
  }

  // returns url for homepage
  here(): string {
    let here = window.location.href.match(/^(.*?).(net|com)/);
    if (!here) {
      here = window.location.href.match(/(.*localhost)(.*?)(\/)/);
      if (here && here[0]) here[0] = here[0].substring(0, here[0].length - 1);
    }
    return here === null ? window.location.href : here[0];
  }

  getIdToken(): string {
    return cookies.get("id_token");
  }

  getAccessToken(): string {
    return cookies.get("access_token");
  }

  getRefreshToken(): string {
    return cookies.get("refresh_token");
  }

  private setRouteRedirect(url: string): void {
    if (!url) cookies.remove("route_redirect", "/");
    else cookies.set("route_redirect", url, undefined, "/");
  }

  // gets route based on current user's cognito group
  async getRoute(): Promise<string> {
    const prevRoute = cookies.get("route_redirect");
    this.setRouteRedirect("");
    const route = prevRoute || "/";
    return route;
  }
}
