// Styles
// import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as labsComponents from "vuetify/labs/components";
import Vue3EasyDataTable from "vue3-easy-data-table";

export default createVuetify({
  components: {
    ...components,
    ...labsComponents,
    ...Vue3EasyDataTable,
  },
  theme: {
    themes: {
      light: {
        colors: {
          brandPrimary: "#0c62fb",
          brandPrimaryWeak: "#5c84cc",
          brandPrimaryForeground: "#ffffff",
          brandAccentColor: "#f0f3f8",
          errorColor: "#E53935",
          errorStrong: "#C62828",
          errorWeak: "#FCE7E7",
          warn: "#FFC107",
          warnStrong: "#FFA000",
          warnWeak: "FFF5E5",
          success: "05ab4d",
          successStrong: "#03892b",
          successWeak: "#d3edde",
          info: "#05ab4d",
          infoStrong: "#03892b",
          infoWeak: "d1d7e0",
          neutralBase: "#F0F2F5",
          neutralContainer: "ffffff",
          neutralHeader: "aaaaaa",
          neutralHighlight: "F0F2F5",
          neutralBorder: "D9D9D9",
          neutralContentBackground: "ffffff",
          neutralContentForeground: "000000",
          neutralHeadingForeground: "000000",
          neutralSubheadingForeGround: "777777",
          neutralInputBackground: "ffffff",
          neutralInputBackgroundDisabled: "eeeeee",
          neutralInputForeground: "000000",
          neutralInputForegroundDisabled: "bbbbbb",
        },
      },
    },
  },
});
