import { KnowledgeArea } from "@/models/knowledgeArea.model";
import { SchemaField } from "../../models/SchemaField";
import { JobExpectation } from "./jobExpectation.model";
import {
  JobSkill,
  mapJobSkillToFrontEnd,
  mapJobSkillToBackEnd,
} from "./jobSkill.model";

export interface JobFamilyFE {
  jobFamilyId: string;
  jobFamilyGroupId: string;
  jobFamilyGroupName: string;
  name: string;
  description: string;
  icon: string;
  knowledgeAreas: KnowledgeArea[];
  skills: JobSkill[];
  expectations: JobExpectation[];
}

export interface JobFamilyBE {
  jobFamilyId: string;
  jobFamilyGroupId: string;
  name: string;
  description: string;
  icon: string;
  knowledgeAreas: KnowledgeArea[];
  skills: JobSkill[];
  expectations: JobExpectation[];
}

export function getJobFamilySchema(): SchemaField[] {
  return [
    {
      name: "name",
      type: "text",
      label: "Job Family Name",
      showInList: true,
      readOnly: false,
    },
    {
      name: "jobFamilyGroupName",
      type: "text",
      label: "Job Family Group",
      showInList: true,
      readOnly: true,
    },
    {
      name: "icon",
      type: "text",
      label: "Icon",
      showInList: false,
    },
    {
      name: "description",
      type: "text",
      label: "Description",
      multiline: true,
      showInList: false,
    },
    {
      name: "skills",
      type: "table",
      label: "Skills",
      elements: {
        type: "job-skill",
      },
    },
    {
      name: "expectations",
      type: "table",
      label: "Expectations",
      elements: {
        type: "job-expectation",
      },
    },
    {
      name: "knowledgeAreas",
      type: "table",
      label: "Knowledge Areas",
      elements: {
        type: "knowledge-area",
      },
    },
  ];
}

export function mapJobFamilyToFrontEnd(
  jobFamily: JobFamilyBE,
  jobFamilyGroupName: string
): JobFamilyFE {
  return {
    jobFamilyId: jobFamily.jobFamilyId,
    jobFamilyGroupId: jobFamily.jobFamilyGroupId,
    jobFamilyGroupName: jobFamilyGroupName,
    name: jobFamily.name,
    description: jobFamily.description,
    icon: jobFamily.icon,
    knowledgeAreas: jobFamily.knowledgeAreas || [],
    skills: mapJobSkillToFrontEnd(jobFamily.skills),
    expectations: jobFamily.expectations || [],
  };
}

export function mapJobFamilyToBackEnd(jobFamily: JobFamilyFE): JobFamilyBE {
  return {
    jobFamilyId: jobFamily.jobFamilyId,
    jobFamilyGroupId: jobFamily.jobFamilyGroupId,
    name: jobFamily.name,
    description: jobFamily.description,
    icon: jobFamily.icon,
    knowledgeAreas: jobFamily.knowledgeAreas || [],
    skills: mapJobSkillToBackEnd(jobFamily.skills),
    expectations: jobFamily.expectations || [],
  };
}
