export const TokenHelper = {
  DecodeToken: <T>(token: string): T | null => {
    try {
      // atob() -- or "A to B" -- is a JS native function that decodes base64 strings
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
      return JSON.parse(atob(token.split(".")[1])) as T;
    } catch {
      return null;
    }
  },
};
