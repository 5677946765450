
import { AuthService } from "./services/auth.service";
import LearningResourceService from "../src/services/learningResource.service";
import RoleService from "../src/services/role.service";
import TechnologyService from "../src/services/technology.service";
import SkillService from "../src/services/skill.service";
import JobService from "../src/jobs/services/job.service";
import SecurityRoleService from "../src/services/security-roles.service";
import SearchService from "../src/services/search.service";
import IndustryService from "./services/industry.service";
import CertificationService from "./services/certification.service";
import { defineComponent } from "vue";

const authService = new AuthService();
const roleService = new RoleService(authService);
const securityRoleService = new SecurityRoleService(authService);
const searchService = new SearchService(authService);
const learningResourceService = new LearningResourceService(
  authService,
  roleService
);
const technologyService = new TechnologyService(authService);
const industryService = new IndustryService(authService);
const skillService = new SkillService(authService);
const jobService = new JobService(authService);
const certificationService = new CertificationService(authService);

export default defineComponent({
  data() {
    return {
      drawer: true,
      items: [
        // {
        //   title: "Learning Resources",
        //   icon: "mdi-school",
        //   route: "/learningResources",
        // },
        {
          title: "Technologies",
          icon: "mdi-fax",
          route: "/technologies",
        },
        {
          title: "Industries",
          icon: "mdi-flask",
          route: "/industries",
        },
        {
          title: "Skills",
          icon: "mdi-account-cog",
          route: "/skills",
        },
        {
          title: "Roles",
          icon: "mdi-human",
          route: "/roles",
        },
        {
          title: "Jobs",
          icon: "mdi-briefcase",
          route: "/jobs",
        },
        {
          title: "Certifications",
          icon: "mdi-certificate-outline",
          route: "/certifications",
        },
        {
          title: "Security Roles",
          icon: "mdi-shield-account",
          route: "/security-roles",
        },
      ],
      right: null,
    };
  },
  provide: {
    authService,
    learningResourceService,
    technologyService,
    skillService,
    roleService,
    jobService,
    securityRoleService,
    searchService,
    industryService,
    certificationService,
  },
  async mounted() {
    authService.init();
  },
});
