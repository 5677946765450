import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import VueAxios from "vue-axios";
import axios from "axios";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .use(VueAxios, axios);
app.mount("#app");
